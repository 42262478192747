import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Cart, 規格情報Modal } from "../../../../shared/types";
import { fromEvent, Subscription } from "rxjs";
import { CartService } from "../../../../shared/service/cart.service";
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-item-modal',
  templateUrl: './item-modal.component.html',
  styleUrls: ['./item-modal.component.scss']
})
export class ItemModalComponent implements OnInit, OnDestroy {

  screen: 'select' | 'added' = 'select';
  index = 0;
  el: 'item' | 'back' = 'item';
  sub: Subscription | undefined;
  lang = '';

  constructor(
    private dialogRef: MatDialogRef<ItemModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: 規格情報Modal,
    private cartService: CartService,
    public translate: TranslateService,
    private http: HttpClient
  ) {
  }
  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  ngOnInit(): void {
    const keyDown$ = fromEvent<KeyboardEvent>(document, 'keydown')
    this.sub = keyDown$.subscribe(d => {
      if (d.key !== 'Enter' && this.screen === 'added') return;
      switch (d.key) {
        case 'ArrowLeft':
          if (this.index === 0 || this.el !== 'item') return;
          this.index--;
          break;
        case 'ArrowRight':
          if (this.index === this.data.options.length - 1 || this.el !== 'item') return;
          this.index++;
          break;
        case 'ArrowUp':
          this.el = 'item';
          break;
        case 'ArrowDown':
          this.el = 'back';
          break;
        case 'Enter':
          if (this.el === 'item') {
            const hit = this.cartService.カート.find(d => {
              return d.originId === this.data.parentId && d.規格情報id === this.data.options[this.index].id
            });
            if (hit) {
              hit.count++;
              this.cartService.sumPriceAndCount();
            } else {
              const data: Cart = {
                itemID: Number(String(this.data.parentId) + String(this.data.options[this.index].id)),
                規格情報id: this.data.options[this.index].id,
                originId: this.data.parentId,
                itemName: this.translate.instant(this.data.parentName) + ' ' + this.translate.instant(this.data.options[this.index].namae),
                price: this.data.options[this.index].price,
                count: 1,
                Time: this.data.Time,
                CategoryType: this.data.CategoryType
              };
              this.cartService.addItem(data);
            }

            this.screen = 'added';
            this.el = 'back';
            return;
          }
          if (this.el === 'back') {
            this.close();
          }
          break;
      }
      this.lang = this.translate.currentLang;
      
    })
  }

  close() {
    this.dialogRef.close();
  }



}
