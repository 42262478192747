import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CartService } from "../../shared/service/cart.service";
import { finalize, firstValueFrom, fromEvent, lastValueFrom, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { WaitComponent } from "./wait/wait.component";
import { AlertComponent } from "../../shared/component/alert/alert.component";
import { DialogComponent } from "../../shared/component/dialog/dialog.component";
import { DelayService } from 'src/app/shared/service/delay.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'cutlery',
  templateUrl: './cutlery.component.html',
  styleUrls: ['./cutlery.component.scss']
})
export class CutleryComponent implements OnInit {

  @ViewChild('amount_row') amount_row: ElementRef | undefined

  items = this.cart.カート;
  items$ = this.cart.カート$.asObservable();
  selectedItem = this.cart.カート[0];
  selectedElement: 'menu' | 'footer' = 'menu';
  // この使わないボタンの扱いはどーすｒｒｒｒｒｒｒｒｒｒｒｒ
  selectedFooterElement: 'confirm' | 'back' = 'confirm';
  rowIndex = 0;
  sub: Subscription[] = [];
  price$ = this.cart.合計金額$.asObservable();
  count$ = this.cart.個数$.asObservable();
  price = this.cart.合計金額;
  count = this.cart.個数;
  scrolled_y = 0;
  offsetTop = 0;
  購入なう = false;
  modalShow = false;
  delayTime = 0;
  timeArray: any = [];
  maxTime = 0;
  sumTime = 0;
  lang = '';
  cutlery = [{ 'category': 'adults', 'count': 0 }, { 'category': 'children', 'count': 0 }]

  constructor(
    private cart: CartService,
    private router: Router,
    private dialog: MatDialog,
    private delayServie: DelayService,
    public translate: TranslateService,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.cutlery[0].count =  this.cart.getDishCount();  
    
    this.cart.sumPriceAndCount();
    this.price$.subscribe(d => {
      this.price = d;
      console.log(d);
    })
    this.count$.subscribe(d => {
      this.count = d;
    })
    this.cart.カート$.subscribe(d => {


      d.map(e => {
        console.log(e);
        this.timeArray.push(e.Time);
        console.log('pushed')
      })

      const aryMax = function (a: number, b: number) { return Math.max(a, b); }
      this.maxTime = this.timeArray.reduce(aryMax);

      console.log(this.timeArray);
      if (this.items.length === d.length) return;
      this.items = d;
      if (this.rowIndex > 0) {
        this.rowIndex--;
      }


    })
    this.sub.push(
      this.delayServie.getDelay().subscribe(d => {
        this.delayTime = d.minute;
        this.sumTime = this.maxTime + this.delayTime;
      }))

    const keyDown$ = fromEvent<KeyboardEvent>(document, 'keydown')
    this.sub.push(keyDown$.subscribe(async d => {
      if (this.modalShow) return;
      if (this.購入なう) return;
      switch (d.key) {
        case 'ArrowLeft':
          if (this.selectedElement === 'menu') {
            if (this.cutlery[this.rowIndex].count > 0) {
              this.cutlery[this.rowIndex].count--;
            }
            break;
          }
          if (this.selectedFooterElement === 'back') {
            this.selectedFooterElement = 'confirm';
          }
          break;
        case 'ArrowRight':
          if (this.selectedElement === 'menu') {
            if (this.cutlery[this.rowIndex].count < 50) {
              this.cutlery[this.rowIndex].count++;
            }

            break;
          }
          if (this.selectedFooterElement === 'confirm') {
            this.selectedFooterElement = 'back';
          }
          break;
        case 'ArrowUp':
          if (this.rowIndex !== 0 && this.selectedElement !== 'footer') this.rowIndex--;
          this.selectedElement = 'menu'; // ↑押されたらmenuにしていい


          //セレクト済の行の位置を取得する
          let offsetTopUp = document.getElementsByClassName('cart_list_kazu')[this.rowIndex].getBoundingClientRect().top
          console.log('offsetTopUP:' + offsetTopUp);
          if (offsetTopUp < 300 && this.scrolled_y >= 83) {
            this.scrolled_y = this.scrolled_y - 83
            //スクロールさせる
            this.amount_row?.nativeElement.childNodes[0].childNodes[0].childNodes[0].scrollTo(0, this.scrolled_y)
            console.log('scrolled Up!');
          }
          d.preventDefault();
          break;
        case 'ArrowDown':
          if (this.rowIndex === 1) {
            this.selectedElement = 'footer';
            break
          }
          this.rowIndex++;

          //セレクト済の行の位置を取得する
          this.offsetTop = document.getElementsByClassName('cart_list_kazu')[this.rowIndex].getBoundingClientRect().top
          if (this.offsetTop > 700) {
            this.scrolled_y = this.scrolled_y + 83
            //スクロールさせる
            this.amount_row?.nativeElement.childNodes[0].childNodes[0].childNodes[0].scrollTo(0, this.scrolled_y)
            console.log('scrolled! Down');
          }
          d.preventDefault();
          break;
        case 'Enter':
          if (this.selectedFooterElement === 'confirm' && this.selectedElement === 'footer') {
            // window.confirm('ご注文してもよろしいですか？');
            const dialogRef = this.dialog.open(DialogComponent, {
              data: {
                title: 'ご注文してもよろしいですか？',
                message: 'ご注文を確定します',
                yesOrNo: 'no',
              }
            })
            this.modalShow = true;
            const isBuy = await lastValueFrom(dialogRef.afterClosed())
            this.modalShow = false;
            if (isBuy) {
              this.購入なう = true;
              const dialogRef = this.dialog.open(WaitComponent);
              const waitList = [0];
              const timeIndex = Math.floor(Math.random() * waitList.length);
              const time = waitList[timeIndex] * 1000;
              console.log(waitList[time]);
              this.cart.buyCart(this.delayTime, this.lang, this.cutlery)
                .pipe(finalize(() => {
                  dialogRef.afterClosed().subscribe(result => {
                    if (result !== false) {
                      const jst = new Date(new Date(result.data.scheduleddelivery_at).getTime() + 9 * 60 * 60 * 1000)
                      this.dialog.open(AlertComponent, {
                        data: {
                          title: 'ご注文が完了しました',
                          message: `お届け予定時刻`,
                          message2: `\n ${this.formatJST(jst)}  `,
                        }
                      });
                      this.cart.clearCart();
                    } else if (result === false) {
                      const alertDialogRef = this.dialog.open(AlertComponent, {
                        data: {
                          title: 'エラーが発生しました',
                          message: '再度ご注文ボタンを押してください 多発するようであれば直通ダイヤルまでお問い合わせください',
                        }
                      })

                      alertDialogRef.afterClosed().subscribe(() => {
                        this.購入なう = false;
                      });
                    } else {
                      const alertDialogRef = this.dialog.open(AlertComponent, {
                        data: {
                          title: '不正な遷移が行われました',
                          message: '注文履歴を確認し、注文されたかご確認ください',
                        }
                      })
                    }
                    this.router.navigate(['']);
                  });
                }))
                .subscribe({
                  next: d => {
                    setTimeout(() => {
                      dialogRef.close(d);
                      // APIのレスポンスが速いとちゅうもんを送っています画面が表示されないので3秒待ってみる
                    }, time);
                  },
                  error: error => {
                    setTimeout(() => {
                      console.log('エラーだよおおおおおおおおおおおおおおおおおおおおおおおおおん')
                      dialogRef.close(false);
                      // APIのレスポンスが速いとちゅうもんを送っています画面が表示されないので3秒待ってみる
                    }, time);
                  }
                })
            }
          }
          if (this.selectedFooterElement === 'back') {
            this.router.navigate(['amount']);
          }
          break;
      }
    }))
    this.lang = this.translate.currentLang;

  }

  ngOnDestroy(): void {
    // 全てのSubscriptionをUnscribeして開放する
    this.sub.forEach(e => {
      e.unsubscribe();
    })
  }

  formatJST(date: Date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

}
