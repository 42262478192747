import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, timeout } from 'rxjs';
import { Item, Menu } from "../types";


@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private MenuApi = environment.MenuApi;
  constructor(
    public http: HttpClient    ) { }

  getMenu(): Observable<[Menu]>{
    console.log('get Menus data from API');
    return this.http.get<[Menu]>(this.MenuApi).pipe(
        timeout(5000)        
    )
  }
}
