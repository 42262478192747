<!-- <div class="debug_window">
    <button (click)="toggleLanguage()">言語切替</button>
    <p>{{ 'example-text' | translate }}</p>
</div> -->
<div class="category_box" [ngClass]="{'active':select_target!='menu'}">
    <ng-scrollbar track="horizontal" position="invertX" #category_box id="categorybox" class="scrollbar">
        <div class="category_box_inner" [style.left]="category_left">
            <ng-container *ngFor="let item of menu_group;let i= index">
                <div class="category_menu" [ngClass]="{
            'CategoryType_1011': item.category_type == 1011 ,
            'CategoryType_1100': item.category_type == 1100 ,
            'CategoryType_1001': item.category_type == 1001 ,
            'CategoryType_2111': item.category_type == 2111 ,
            'CategoryType_3111': item.category_type == 3111 ,
            'focus': page_active === i
            }">
                    <p>{{item.category_name | translate}}</p>
                </div>
            </ng-container>
        </div>
    </ng-scrollbar>
</div>

<div class="main_menu">
    <div class="menu_box" [@scrollMenu]="{value:scrollMenu, params:{left:left, stopped_left:stopped_left}}"
        (@scrollMenu.done)="onAnimationEvent()">
        <div *ngFor="let page of menu_list" class="page" [ngClass]="{
            'CategoryType_1011': page.CategoryType == 1011 ,
            'CategoryType_1100': page.CategoryType == 1100 ,
            'CategoryType_1001': page.CategoryType == 1001 ,
            'CategoryType_2111': page.CategoryType == 2111 ,
            'CategoryType_3111': page.CategoryType == 3111
        }">
            <ng-container *ngFor="let item of page.Item">
                <div class="item" [ngClass]="{
                    'item_1x1' : item.Class=='1x1',
                    'item_1x2' : item.Class=='1x2',
                    'item_3x2' : item.Class=='3x2',
                    'focus': item.focus && select_target === 'menu' }">
                    <div class="img_box">
                        <img [lazyLoad]="item.Image" class="menu_image" alt="{{item}}" [offset]="1500" />
                    </div>
                    <div class="spec">
                        <div class="name" [ngStyle]="{'font-size': getFontSize(item.Name, item.Class, translate.currentLang)}">{{item.Name | translate}}</div>
                        <div class="subname_box">
                            <div *ngIf="translate.currentLang === 'ja'">
                                <div class="time_box" *ngIf="item.Time == 0 ;else notitemTime0">
                                    <div classs="time_0">
                                        <div class="time_circle_0">
                                            <span class="time_num" translate>すぐに</span>
                                        </div>
                                    </div>
                                    <div class="time_deliver" translate>お届け</div>
                                </div>
                                <ng-template #notitemTime0>
                                    <div class="time_box">
                                        <div classs="time">
                                            <div class="time_circle">
                                                <span>約</span>
                                                <span class="time_num">{{item.Time + delayTime}}</span>
                                                <span>分</span>
                                            </div>
                                        </div>
                                        <div class="time_deliver">でお届け</div>
                                    </div>
                                </ng-template>
                            </div>
                            <div *ngIf="translate.currentLang === 'en'">
                                <div class="time_box" *ngIf="item.Time == 0 ;else notitemTime0">
                                    <div classs="time_0">
                                        <div class="time_circle_0">
                                            <span class="time_num" translate>すぐに</span>
                                        </div>
                                    </div>
                                    <div class="time_deliver" translate>お届け</div>
                                </div>
                                <ng-template #notitemTime0>
                                    <div class="time_box">
                                        <div class="time_deliver">Deliver</div>
                                        <div classs="time">
                                            <div class="time_circle">
                                                <span class="time_num">{{item.Time + delayTime}}</span>
                                                <span>min.</span>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                            <div class="subname">{{item.SubName | translate}}</div>
                            <div class="price">¥{{item.Price | number }}</div>
                        </div>

                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="menu_box_before_gradation"></div>
    <div class="menu_box_before"></div>
    <div class="menu_box_after_gradation"></div>
    <div class="menu_box_after"></div>
</div>

<!-- <div class="cart_icon_box">
    <div class="footer_price">
        <p>合計</p>
        <div class="footer_price_num">
            <p>¥ <span>{{price | async | number}}</span></p>
        </div>
    </div>
    <div class="num">
        <div class="num_svg"><img src="/assets/footer/cart.svg"></div>
        <div class="num_small">{{count | async }}</div>
    </div>
</div> -->

<div class="footer">
    <!-- <div class="phone">
        <div class="phone_01">直通ダイヤル</div>
        <div class="phone_02">
            <img src="./assets/header/phone.svg">
            <div>92</div>
        </div>
    </div> -->
  <div class="buttons">
<!--    <ng-container *ngIf="isBusinessTime$ | async as isBusinessTime"-->
<!--    >-->
<!--      <a *ngIf="hoge"  [class]="'cart' | footerFocus:footer_target:select_target">-->
<!--        <div class="button_text">数量変更・ご注文</div>-->
<!--      </a>-->

<!--      <a *ngIf="!hoge"  [class]="'cart' | footerFocus:footer_target:select_target">-->
<!--        <div class="buttonDisable">注文時間外です</div>-->
<!--      </a>-->
<!--    </ng-container>-->

<!--  TODO: 時間外でも注文出来るように一旦ngIF消してるお   -->
    <a *ngIf="isBusinessTime$ | async as isBusinessTime else elseComponent" [class]="'cart' | footerFocus:footer_target:select_target:time">

        <div class="footer_button_cart">
            <div class="num_svg"><img src="/assets/footer/cart.svg"></div>
            <div class="num_small">{{count | async }}</div>
            <div translate>注文</div>
        </div>

    </a>

    <ng-template #elseComponent>
      <a  [class]="'cart' | footerFocus:footer_target:select_target:time">
        <div class="button_text" translate>営業時間外です</div>
      </a>
    </ng-template>

    <a [class]="'allergy' | footerFocus:footer_target:select_target:time">
      <div class="button_text" translate>注文履歴</div>
    </a>
    <a [class]="'back' | footerFocus:footer_target:select_target:time">
      <div class="button_text" translate>もどる</div>
    </a>
  </div>
</div>
