import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { History } from "../../shared/types";
import { map } from "rxjs";
import { RoomNoService } from "../../shared/roomNoService";

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(private http: HttpClient, private roomNoService: RoomNoService) {
  }
  getHistory() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Basic ` + btoa(environment.ApiUser + ':' + environment.ApiPass),
    });
    return this.http.get<History>(environment.OrderApi + this.roomNoService.roomNo, { headers })
      .pipe(map((data) => {
        const tmp = data.data.map(item => {
          item.detail.forEach(d => {
            d.flag_delivered = item.flag_delivered;
            d.flag_canceled = item.flag_canceled;
            if (item.scheduleddelivery_at && !isNaN(new Date(item.scheduleddelivery_at).getTime())) {
              const scheduledDeliveryDate = new Date(item.scheduleddelivery_at);
              d.scheduleddelivery_at = this.formatJST(new Date(scheduledDeliveryDate.getTime() + 9 * 60 * 60 * 1000));
            }
          })
          return item.detail;
        }).flat(1);
        return tmp.map((item, index) => {
          if (item.created_at && !isNaN(new Date(item.created_at).getTime())) {
            const createdAtDate = new Date(item.created_at);
            item.created_at = this.formatJST(new Date(createdAtDate.getTime() + 9 * 60 * 60 * 1000));
          }
          return item;
        })
      }))
  }

  formatJST(date: Date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
}
