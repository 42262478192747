import {Injectable} from "@angular/core";
import {BehaviorSubject, interval, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NowTimeService {
  constructor() {
    setInterval(() => {
        this.subject.next(this.getNowDateObject(new Date(), 'jpn'));
    }, 1000);

    this.subject.subscribe((data) => {
      this.isbusinessTimeBetweenSubject.next(this.isTimeBetween())
    });
  }
  subject = new BehaviorSubject(this.getNowDateObject(new Date(), 'jpn'));
  date$ = this.subject.asObservable();
  isbusinessTimeBetweenSubject = new BehaviorSubject<boolean>(this.isTimeBetween());


  get date()  {
    return this.date$;
  }

  isTimeBetween(startHour = 11, endHour = 21,now?: Date){
    if (now == null) {
      now = this.subject.value.date;
    }
    const currentHour = now.getHours();

    return currentHour >= startHour && currentHour < endHour;
  }

  getNowDateObject(d: Date, lang: 'jpn' | 'eng'){
    const day_of_week = () => {
      if (lang === 'jpn') {
        return ['日', '月', '火', '水', '木', '金', '土'];
      } else if (lang === 'eng') {
        return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      }
      return ['日', '月', '火', '水', '木', '金', '土'];
    };
    return {
      year: d.getFullYear().toString(),
      month: ('00' + (d.getMonth() + 1)).slice(-2),
      day: ('00' + d.getDate()).slice(-2),
      hhmm:
        ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2),

      day_of_week: day_of_week()[d.getDay()],
      date:d
    };
  }
}

export type nowDate = {
  year: string;
  month: string;
  day: string;
  day_of_week: string;
  hhmm: string;
  date:Date;
};
