import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { 規格情報Modal } from "../../types";
import { fromEvent, Subscription } from "rxjs";
import { ModeService } from "../../service/mode.service";
import { RoomNoService } from '../../roomNoService';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  sub!: Subscription
  HIS = '';
  lang = '';

  constructor(
    private dialogRef: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertType,
    private modeService: ModeService,
    private roomNoService: RoomNoService,
    public translate: TranslateService,
    private http: HttpClient
  ) {
    this.HIS = environment.HIS
  }

  ngOnInit(): void {

    this.modeService.mode = 'item';
    const keyDown$ = fromEvent<KeyboardEvent>(document, 'keydown');
    this.sub = keyDown$.subscribe(d => {
      if (d.key === 'Enter') {

        console.log(this.data.goto);
        if (this.data.goto === 'his') {
          window.location.href = this.HIS + this.roomNoService.roomNo;
        }
        this.dialogRef.close();
      }
    });
    this.dialogRef.afterClosed().subscribe(() => {
      this.modeService.mode = 'menu';
    });

    this.lang = this.translate.currentLang;
    

  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

}

type AlertType = {
  title: string;
  message: string;
  message2: string;
  goto: string;

}
