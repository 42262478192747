import { Pipe, PipeTransform } from '@angular/core';
import {FooterTypes} from './footerTypes';
import {NowTimeService} from "../../../shared/service/nowTime.service";
@Pipe({
  name: 'footerFocus'
})
export class FooterFocusPipe implements PipeTransform {

  transform(value: Exclude<FooterTypes, ''>, footer_target: FooterTypes, select_target:string,nowTime:boolean): string {
    const obj = {
      cart:'01',
      allergy:'02',
      back:'03',
      time:'04',
    }
    let ngClass = '';
    console.log(nowTime);
    if ( nowTime && value === 'cart') {
      ngClass = `button${obj[value]}`;
    } else if (value === 'cart') {
      ngClass = `button05`;
    } else {
      ngClass = `button${obj[value]}`;
    }
    if (footer_target === '') {
      return ngClass
    }
    if (value === footer_target && select_target === 'footer'){
      ngClass = `${ngClass} focus`;
    }
    return ngClass;
  }



}
