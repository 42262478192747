import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { MainMenuComponent } from './pages/main/main-menu/main-menu.component';
import { ItemModalComponent } from './pages/main/main-menu/item-modal/item-modal.component';
import { MatDialogModule } from "@angular/material/dialog";
import { NgScrollbarModule } from 'ngx-scrollbar';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { AllergyComponent } from './pages/allergy/allergy.component';
import { AmountComponent } from './pages/amount/amount.component';
import { FooterFocusPipe } from './pages/main/main-menu/footer-focus.pipe';
import { MainComponent } from './pages/main/main.component';
import { HistoryComponent } from './pages/history/history.component';
import { AuthInterceptor } from "./auth.interceptor";
import { WaitComponent } from './pages/cutlery/wait/wait.component';
import { DialogComponent } from "./shared/component/dialog/dialog.component";
import { AlertComponent } from "./shared/component/alert/alert.component";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { CutleryComponent } from './pages/cutlery/cutlery.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    '.json?cb=' + new Date().getTime()
  );
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SideBarComponent,
    MainMenuComponent,
    ItemModalComponent,
    AllergyComponent,
    AmountComponent,
    FooterFocusPipe,
    MainComponent,
    HistoryComponent,
    WaitComponent,
    DialogComponent,
    AlertComponent,
    CutleryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    NgScrollbarModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'ja',
    }),
    LazyLoadImageModule,
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.use('ja');
  }
}

