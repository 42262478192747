<div *ngIf="count == 0; else rirekiContent">
  <div class="top_text top_text_title_center">
    <div class="top_text_title" translate>ご注文履歴</div>
  </div>
  <div class="history_zero">
    <div class="history_zero_svg"><img src="../assets/history/note.svg"></div>
    <div class="history_zero_text" translate>注文履歴がありません</div>
  </div>
</div>
<ng-template #rirekiContent>
  <div class="top_text">
    <div class="top_text_title" translate>ご注文履歴</div>
    <!--注文履歴の合計はこっちに記載をする。-->
    <div class="top_text_money">
      <div class="top_1" translate>合計金額</div>
      <div class="top_2">{{sum | number}}</div>
      <div class="top_3" translate>円</div>
    </div>
    <div class="top_text_num">
      <div class="top_1" translate>注文点数</div>
      <div class="top_2">{{count}}</div>
      <div class="top_3" translate>点</div>
    </div>
  </div>
  <div class="cart_box">
    <div class="cart_title">
      <div></div>
      <div class="cart_title_time" translate>注文時間</div>
      <div class="cart_title_time" translate>お届け時間</div>
      <div class="cart_title_shina" translate>品名</div>
      <div class="cart_title_kazu" translate>数量</div>
    </div>

    <div class="cart_list">
      <ng-scrollbar>
        <div class="cart_list_small" *ngFor="let item of items">
          <div>
            <div *ngIf="item.flag_canceled == 1" class="cart_list_status cart_canceled" translate>
              キャンセル</div>
            <!--
              <ng-template #notcanceled>
                <div *ngIf="item.flag_delivered == 1" class="cart_list_status cart_delivered" translate>配達済み</div>
                <div *ngIf="item.flag_delivered == 0" class="cart_list_status cart_prepareing" translate>準備中</div>
              </ng-template>
            -->
          </div>
          <div class="cart_list_time">{{item.created_at | date: 'HH:mm'}}</div>
          <div class="cart_list_time">{{item.scheduleddelivery_at | date: 'HH:mm'}}</div>
          <div *ngIf="item.kikaku_id" class="cart_list_shina">{{item.item_name | translate}} <span
              translate>{{item.kikaku_name}}</span></div>
          <div *ngIf="item.kikaku_id == null" class="cart_list_shina">{{item.item_name |translate}} </div>
          <div class="cart_list_kazu">{{item.quantity}}</div>

        </div>
      </ng-scrollbar>
    </div>
  </div>
</ng-template>

<div class="footer">
  <!-- <div class="num">
    <div class="num_small">20</div>
  </div>
  <div class="footer_price">
    <p class="footer_price_sum">合計</p>
    <div class="footer_price_num">
      <p>¥10000</p>
    </div>
  </div> -->
  <div class="buttons">
    <a class="button01">
      <div class="button_text"></div>
    </a>
    <a class="button02">
      <div class="button_text"></div>
    </a>
    <a class="button03" [ngClass]="back ? 'focus' : ''">
      <div class="button_text" translate>もどる</div>
    </a>
  </div>
</div>