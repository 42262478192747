<div class="modal_box">
    <div class="modal_top_text">
      <p>{{data.title |translate}}</p>
      <p class="small_text">{{data.message | translate}}</p>
      <p class="small_text">{{data.message2 | date: 'HH:mm'}}</p>
    </div>
    <div class="select_position">
      <button class="button04 focus" translate>もどる</button>
    </div>
</div>
