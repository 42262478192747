import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-allergy',
  templateUrl: './allergy.component.html',
  styleUrls: ['./allergy.component.scss']
})
export class AllergyComponent implements OnInit {

  constructor(private  dialogRef:MatDialogRef<null>
    ) { }
  
    ngOnInit(): void {
    }
  
    close() {
      this.dialogRef.close();
    }
  
  }
  
