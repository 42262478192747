import {Injectable} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class RoomNoService {
  roomNo = 0;
  roomNo$ = new BehaviorSubject<number>(0);
  constructor(private acriveRoute: ActivatedRoute) {
    this.acriveRoute.queryParamMap
      .subscribe(params => {
        console.log('queryParamMap', params.get('room_id'));
      this.roomNo = params.get('room_id') ? Number(params.get('room_id')) : this.roomNo;
      this.roomNo$.next(this.roomNo);
    });
  }
}
