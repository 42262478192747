import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";
import { Cart, Item } from "../types";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { RoomNoService } from "../roomNoService";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { AlertComponent } from "../component/alert/alert.component";

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private http: HttpClient, private roomNoService: RoomNoService,
    private router: Router,
    private dialog: MatDialog
  ) {
  }
  #合計金額 = 0;
  #個数 = 0;
  #カート: Cart[] = [];
  合計金額$ = new BehaviorSubject<number>(0);
  個数$ = new BehaviorSubject<number>(0);
  カート$ = new BehaviorSubject<Cart[]>(this.カート);
  langString: String = '';
  set 合計金額(合計金額: number) {
    console.log(合計金額);
    this.合計金額$.next(合計金額);
    this.#合計金額 = 合計金額;
  }
  get 合計金額() {
    return this.#合計金額;
  }
  set 個数(個数: number) {
    this.個数$.next(個数);
    this.#個数 = 個数;
  }
  get 個数() {
    return this.#個数;
  }
  addItem(カート: Cart) {
    if (this.個数 >= 50) return;
    this.#カート.push(カート);
    this.カート$.next(this.#カート);
    this.sumPriceAndCount();
  }
  get カート() {
    return this.#カート;
  }
  set カート(カート: Cart[]) {
    this.#カート = カート;
    this.カート$.next(this.#カート);
  }

  addItemCount(id: number) {
    if (this.個数 >= 50) {
      // 合計値が99以上なら加算しない
      return;
    }
    const target = this.#カート.find(el => el.itemID === id);
    if (target) {
      target.count++;
      this.sumPriceAndCount();
    }
  }

  subItemCount(id: number) {
    const target = this.#カート.find(el => el.itemID === id);
    if (!target) return;
    if (target.count > 0) {
      target.count--;
      this.sumPriceAndCount();
    }
    this.カート = this.#カート.filter(el => el.count > 0);
    if (this.カート.length === 0) {
      const dialogRef = this.dialog.open(AlertComponent, {
        data: {
          title: 'カートが空です',
          message: 'メインメニューにもどります'
        }
      })
      dialogRef.afterClosed().subscribe(() => {
        this.router.navigate(['/']);
      });
    }
  }

  getDishCount() {
    //料理の数を答えます

    let counter = 0;
    this.カート.forEach(element => {      
      if(String(element.CategoryType)[0] === '1'){
        counter = counter + element.count;
      }      
      console.log('Number of dish is' + counter)    ;
    });
    return counter
  }

  sumPriceAndCount() {
    console.log('aaaaaa');
    this.合計金額 = this.#カート.reduce((acc, cur) => {
      return acc + (cur.price * cur.count);
    }, 0);
    this.個数 = this.#カート.reduce((acc, cur) => {
      return acc + cur.count;
    }, 0);
  }

  buyCart(delayTime: Number, lang: string, people: Array<any>) {
    const url = environment.OrderApi + this.roomNoService.roomNo;
    const body = this.#カート.map(el => {
      return {
        ItemID: el.規格情報id ? el.originId : el.itemID,
        Quantity: el.count,
        Price: el.price,
        KikakuID: el.規格情報id,
      }
    });


    // 標準配達時間の最大値を求める
    const TimeArray: any = []
    this.#カート.map(el => {
      TimeArray.push(el.Time);
    })

    const aryMax = function (a: number, b: number) { return Math.max(a, b); }
    console.log(TimeArray)
    let maxTime = TimeArray.reduce(aryMax);
    const DeliveryTime = maxTime;
    this.clearCart();

    // langの正規化
    if (lang == 'ja') {
      this.langString = 'ja_JP'
    } else {
      this.langString = 'en_US'
    }

    return this.http.post(url,
      {
        'DeliveryTimeStandard': DeliveryTime,
        'DeliveryTimeDelay': delayTime,
        'Language': this.langString,
        "Items": body,
        "People":{'Adults':people[0].count,'Children':people[1].count}
      }
    )
  };


  clearCart() {
    this.カート = [];
    this.sumPriceAndCount();
  }
}
