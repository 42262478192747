<div class="modal_box">
  <!--ここから１ページ目-->
  <section *ngIf="screen === 'select'">
    <div class="modal_top_text">
      <p>{{data.optionName | translate}}</p>
      <p class="small_text" translate>ご注文メニューには下記のオプションがあります。</p><!--ここは固定-->
      <p class="small_text" translate>左右キーを選んで決定ボタンを押してください。</p><!--ここは固定-->
    </div>
    <div class="select">
      <div class="select_box" *ngFor="let option of data.options;let i=index">
        <button class="select_button" [ngClass]="{
          'focus': i === index && screen === 'select' && el === 'item'
        }"  ><img [src]="option.Image"></button>
        <p class="select_text">{{option.namae | translate}}</p>
        <p class="select_price">¥ {{option.price | number}}</p>
      </div>
    </div>
    <button class="button04"
            [ngClass]="{'focus': el === 'back' && screen === 'select'}"
            (click)="close()" translate="">もどる</button>
    </section>
  <!--ここまで１ページ目-->

  
  
  <!--ここから２ページ目-->
  <section *ngIf="screen === 'added'">
    <div class="modal_top_text">
      <p translate>セット追加</p><!--ここの文字は動的-->
      <p class="small_text" translate>下記オプションが追加されました。</p><!--ここは固定-->
      <p class="small_text" translate>数量確認画面でキャンセルが可能です。</p><!--ここは固定-->
    </div>
    <div class="select">
      <div class="select_box"><!--１ページ目で選択したものを表示-->
        <div class="select_button"><img [src]="data.options[index].Image"></div>
        <p class="select_text">{{data.options[index].namae | translate}}</p>
        <p class="select_price">¥{{data.options[index].price | number}}</p>
      </div>
    </div>
    <button class="button04 focus" (click)="close()" translate>もどる</button>
    </section>
  <!--ここまで2ページ目-->
</div>
