import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {fromEvent} from "rxjs";

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogType,
  ) { }
  yesOrNo: 'yes' | 'no' = this.data.yesOrNo;

  ngOnInit(): void {
    const kewDown$ = fromEvent<KeyboardEvent>(document, 'keydown');
    kewDown$.subscribe(d => {
      switch (d.key) {
        case 'ArrowLeft':
          this.yesOrNo = 'yes';
          break;
        case 'ArrowRight':
          this.yesOrNo = 'no';
          break;
        case 'Enter':
          this.dialogRef.close(this.yesOrNo === 'yes' ? true : false);
      }
    });
  }

}

type DialogType = {
  title: string;
  message: string;
  message2: string;
  yesOrNo: 'yes' | 'no';
}
