<!-- <div class="debug_window">
scrolled_y:{{scrolled_y}}<br >
offsetTop: {{offsetTop}}
</div> -->
<div class="top_text">
  <div class="top_text_title" translate>数量の確認</div>
  <div class="top_text_list">
    <ul>
      <li translate>ご注文内容を確認してください。</li>
      <li translate>数量は左右ボタンで変更ができます。</li>
      <li translate>確認後に「ご注文」を押してください。</li>
    </ul>
  </div>
</div>
<div class="cart_box">
  <div class="cart_title">
    <div class="cart_title_shina" translate>品名</div>
    <div class="cart_title_kazu" translate>数量</div>
  </div>

  <div class="cart_list">
    <ng-scrollbar #amount_row>
      <ng-container *ngIf="items$ | async as cartItems" >
      <div *ngFor="let item of cartItems;let i=index" class="cart_list_small">
        <div class="cart_list_shina" [ngClass]="{
      'cart_list_focus':item.itemID === cartItems[rowIndex].itemID
      && selectedElement === 'menu'}">
          <div class="cart_list_item_name">{{item.itemName | translate}}</div>
          <div class="cart_list_item_price">¥ {{item.price | number}}</div>
        </div>
        <div class="cart_list_kazu" [ngClass]="{
          'cart_list_kazu_focus':item.itemID === items[rowIndex].itemID
          && selectedElement === 'menu'}">
          <div class="cart_list_kazu_minus"><svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" aria-labelledby="minusIconTitle" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="miter" fill="none"> <title id="minusIconTitle">Minus</title> <path d="M20,12 L4,12"/> </svg></div>
          <div class="cart_list_kazu_square">{{item.count}}</div>
          <div class="cart_list_kazu_plus"><svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" aria-labelledby="plusIconTitle" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="miter" fill="none"> <title id="plusIconTitle">Plus</title> <path d="M20 12L4 12M12 4L12 20"/> </svg></div>
        </div>
      </div>
      </ng-container>
    </ng-scrollbar>
  </div>
  <div class="bottom_text">
    <div class="top_text_delay" *ngIf="translate.currentLang === 'ja'">
      <div class="top_1">約</div>
      <div class="top_2">{{sumTime | number}}</div>
      <div class="top_3">分でお届け予定</div>
    </div>
    <div class="top_text_delay" *ngIf="translate.currentLang === 'en'">
      <div class="top_1">deliver about</div>
      <div class="top_2">{{sumTime | number}}</div>
      <div class="top_3" translate>min.</div>
    </div>
  <div class="top_text_money">
    <div class="top_1" translate>合計金額</div>
    <div class="top_2">{{price | number}}</div>
    <div class="top_3" translate>円</div>
  </div>
  <div class="top_text_num">
    <div class="top_1" translate>注文点数</div>
    <div class="top_2">{{count}}</div>
    <div class="top_3" translate>点</div>
  </div>
  </div>
  <div class="zei" translate>※料金は、税金・サービス料込みでございます</div>
</div>

<div class="cart_icon_box">
  <!-- <div class="footer_price">
      <p>合計</p>
      <div class="footer_price_num">
          <p>¥ <span>{{price | async | number}}</span></p>
      </div>
  </div> -->
  <!-- <div class="num">
      <div class="num_svg"><img src="/assets/footer/cart.svg"></div>
      <div class="num_small">{{count}}</div>
  </div> -->
</div>

<div class="footer">
  <!-- <div class="phone">
      <div class="phone_01">直通ダイヤル</div>
      <div class="phone_02">
          <img src="./assets/header/phone.svg">
          <div>92</div>
      </div>
  </div> -->
  <div class="buttons">
    <a class="button01" [ngClass]="{'focus':selectedFooterElement === 'confirm'
    && selectedElement === 'footer'
    }">
      <div class="button_text" translate>ご注文</div>
    </a>
    <a class="button02">
      <div class="button_text"></div>
    </a>
    <a class="button03" [ngClass]="{'focus':selectedFooterElement === 'back'
    && selectedElement === 'footer'
    }">
      <div class="button_text" translate>もどる</div>
    </a>
  </div>
</div>
