<div class="side_bar" *ngIf="now_date_obj$ | async as now_date_obj">
  <div class="clock">
    <div class="date">
      <div class="year">{{ now_date_obj.year }}.</div>
      <div class="month-date">{{ now_date_obj.month }}.{{ now_date_obj.day }}</div>
      <div class="week">{{ now_date_obj.day_of_week | translate}}</div>
      <div class="time">
        {{ now_date_obj.hhmm }}
      </div>
    </div>

  </div>
  <div class="eigyo_box">
    <div [ngClass]="{
      'scine_on': businessHours == 'OPEN',
      'scine_off': businessHours == 'CLOSE'
  }">
      <div class="scine_text">{{businessHours | translate}}</div>
    </div>
    <div class="eigyo">
      <p class="eigyo_time" translate>提供時間</p>
      <p class="eigyo_num" translate>11:00~21:00</p>
    </div>
  </div>
  <!-- <div class="status_title">ご注文の流れ</div> -->
  <!--
      モーニング：scine_mを表示
      ランチ：scine-lを表示
      ディナー:scine-dを表示
    -->
  <!-- <div class="status">
    <div class="status_box">
      <div class="status_num"><p>1</p></div>
      <div class="status_text">
        メニュー選択
      </div>
      <p>上下左右のキーでメニューを選び<br />決定ボタンでカートに追加します</p>
    </div>
    <div class="status_box">
      <div class="status_num"><p>2</p></div>
      <div class="status_text">
        数量確認
      </div>
      <p>選ぶメニューが決まりましたら、<br />左下ボタンで 数量確認 の画面へ</p>
    </div>
    <div class="status_box">
      <div class="status_num"><p>3</p></div>
      <div class="status_text">
        ご確認
      </div>
      <p>数量などの確認が終わりましたら<br />最終の決定をお願いします</p>
    </div>
    <div class="status_box">
      <div class="status_num"><p>3</p></div>
      <div class="status_text">
        ご注文
      </div>
      <p>ご注文を承りました<br />お届けまでしばらくお待ち下さい</p>
    </div>
  </div> -->
</div>