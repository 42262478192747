import { Component, OnInit } from '@angular/core';
import {NowTimeService} from "../shared/service/nowTime.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

  constructor(private nowTimeService:NowTimeService) { }
  now_date_obj$ = this.nowTimeService.date$;
  businessHours: 'OPEN' | 'CLOSE' = 'OPEN';
  isBusinessHours = true;
  sub!: Subscription;
  ngOnInit(): void {
    this.sub = this.nowTimeService.date$.subscribe(d => {
      this.isBusinessHours = this.nowTimeService.isTimeBetween(11, 21, d.date);
      if (this.isBusinessHours) this.businessHours = 'OPEN';
      else this.businessHours = 'CLOSE';
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
