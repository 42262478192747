<div class="modal_box">
  <div class="modal_top_text">
    <p>{{data.title | translate}}</p>
    <p class="small_text">{{data.message | translate}}</p>
    <p class="small_text">{{data.message2 | translate}}</p>
  </div>
  <div class="select_position">
    <button class="button04" translate
      [ngClass]="yesOrNo === 'yes' ? 'focus' : ''"
    >はい</button>
    <button class="button04" translate
      [ngClass]="yesOrNo === 'no' ? 'focus' : ''"
    >いいえ</button>
  </div>
</div>
