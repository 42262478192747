import { Injectable } from '@angular/core';
import {Subject} from "rxjs";


type mode = 'item'|'menu';
@Injectable({
  providedIn: 'root'
})
export class ModeService {
  #mode:mode = 'menu';
  subject = new Subject<'item'|'menu'>();
  constructor() { }
  set mode(mode: mode) {
    this.subject.next(mode);
    this.#mode = mode;
  }

  get mode() {
    return this.#mode;
  }


}
