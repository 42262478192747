import { Injectable } from '@angular/core';
import {Item} from "../types";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  #item!: Item;
  item$ = new Subject<Item>();
  constructor() { }

  set item (item: Item) {
    this.item$.next(item);
    this.#item = item;
  }
  get item(): Item {
    return this.#item;
  }

}
