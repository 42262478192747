import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (request.url.includes(environment.AuthDomain)) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization:
            'Basic ' + btoa(environment.ApiUser + ':' + environment.ApiPass),
        },
      });
    }

    return next.handle(request);
  }
}
