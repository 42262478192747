<div class="modal_box">
  <div class="modal_top_text" translate>ご注文を確認しています</div>
  <div class="loader">
      <svg class="cart_svg" data-name="cart_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 925.34 600">
          <g class="cart" data-name="cart">
              <line class="cart_line_top" x1="16.76" y1="292.5" x2="197.6" y2="292.5"/>
              <line class="cart_line_middle" x1="85.2" y1="368.36" x2="266.03" y2="368.36"/>
              <line class="cart_line_bottom" x1="153.63" y1="444.22" x2="334.46" y2="444.22"/>
              <path class="cart_left" data-name="cart left"  d="m493.45,529.3c0-33.27,27.07-60.34,60.34-60.34s60.34,27.07,60.34,60.34-27.07,60.34-60.34,60.34-60.34-27.07-60.34-60.34h0Zm36.6,0c0,13.09,10.65,23.74,23.74,23.74s23.74-10.65,23.74-23.74-10.65-23.74-23.74-23.74-23.74,10.65-23.74,23.74Z"/>
              <path class="cart_right" data-name="cart right"  d="m741.09,529.3c0-33.27,27.07-60.34,60.34-60.34s60.34,27.07,60.34,60.34-27.07,60.34-60.34,60.34-60.34-27.07-60.34-60.34Zm36.6,0c0,13.09,10.65,23.74,23.74,23.74s23.74-10.65,23.74-23.74-10.65-23.74-23.74-23.74-23.74,10.65-23.74,23.74h0Z"/>
              <g class="cart_box">
                <path id="cart_box-2" data-name="cart box"  d="m321.32,211h118.26c9.97,0,18.71,6.67,21.34,16.3l77.92,186.68h268.54l72.01-137.43h45.95c-.14.81-.33,1.63-.57,2.44l-79.63,163.34c-2.77,9.41-11.41,15.87-21.22,15.87h-301.98c-9.97,0-18.71-6.67-21.34-16.3l-77.91-186.67h-101.37c-12.22,0-22.12-9.9-22.12-22.12s9.9-22.11,22.12-22.11Z"/>
                <rect  x="566.32" y="129" width="89.47" height="123.97"/>
                <rect  x="675.61" y="153.95" width="156.14" height="72.13"/>
                <rect  x="675.61" y="243.34" width="125.5" height="62.03"/>
                <rect  x="550.24" y="270.22" width="105.54" height="129.07"/>
                <rect  x="675.61" y="322.62" width="119.8" height="76.67"/>
              </g>
          </g>
      </svg>
  </div>
</div>