import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-wait',
  templateUrl: './wait.component.html',
  styleUrls: ['./wait.component.scss']
})
export class WaitComponent implements OnInit {

  lang = '';
  
  constructor(
    private  dialogRef:MatDialogRef<null>,
    public translate: TranslateService,
    private http: HttpClient
    ) { }
  
    ngOnInit(): void {
          // Languageをサービスしちゃう
    this.lang = this.translate.currentLang;
    this.translate.use(this.lang);

    }
  
    close() {
      this.dialogRef.close();
    }

}
