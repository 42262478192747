import { Component, OnInit } from '@angular/core';
import {RoomNoService} from "../shared/roomNoService";
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  lang = '';

  constructor(
    private roomNoService:RoomNoService,
    public translate: TranslateService,
    private http: HttpClient
    ) { }

  roomNo$ = this.roomNoService.roomNo$.asObservable();
  ngOnInit(): void {

  this.lang = this.translate.currentLang;
  
  }

}
