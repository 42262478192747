<div class="header">
    <!-- <div class="title"><img src="./assets/header/logo.png"></div> -->

    <div class="phone">
        <div class="phone_01"  translate>直通ダイヤル</div>
        <div class="phone_02">
            <img src="./assets/header/phone.svg">
            <div>92</div>
        </div>
    </div>

    <!-- <div class="phone">
        <div class="phone_01">ルームサービス直通ダイヤル</div>
        <div class="phone_02">
            <img src="./assets/header/phone.svg">
            <div>92</div>
        </div>
    </div> -->
    <!-- <div class="room_box">
        <div class="room_text">こちらのお部屋</div>
        <div class="room_flex">
            <p class="room_num">{{roomNo$ | async}}</p>
            <p class="room_ban">号室</p>
        </div>
    </div> -->
</div>
<div class="logo">
    <img src="./assets/header/f_logo.png">
</div>